<template>
  <TheWrapper>
    <div class="label-creation">
      <FontAwesomeIcon
        class="label-creation__spinner"
        :icon="$options.faSpinner"
        size="5x"
        spin
      />
    </div>
    <transition name="fade">
      <div
        v-if="hasElapsedTwoSeconds"
        class="label-creation__feedback"
      >
        <strong v-if="returnLocationType === 'pickup'">{{ $t('We are processing your return request') }}</strong>
        <strong v-else>{{ $t('We are creating the label') }}</strong>
        <span>{{ $t('This might take a few seconds') }}</span>
      </div>
    </transition>
  </TheWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import TheWrapper from '@/components/layout/TheWrapper.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { APP_FETCH_LABEL_STATUS } from '@/store/action.types.js'
import { ErrorTypes } from '@/constants.js'
import { handleRouteRejection } from '@/utils'

export default {
  name: 'ReturnLabelCreation',
  faSpinner,
  components: {
    FontAwesomeIcon,
    TheWrapper,
  },

  data() {
    return {
      creatingLabel: false,
      showFeedbackTimeout: undefined,
      hasElapsedTwoSeconds: false,
    }
  },

  computed: {
    ...mapGetters(['returnSuccess']),
    ...mapState({
      returnLocationType: (state) => state.returnLocationType,
    }),
  },

  async created() {
    this.startFeedbackTimeout()
    this.creatingLabel = true

    if (!this.returnSuccess) {
      try {
        // wait until we get the label.
        await this.$store.dispatch(APP_FETCH_LABEL_STATUS, { maxTries: 20, delay: 1500 })
      } catch (err) {
        // Polling the label URL somehow failed, too bad (for now)
        this.$router.push({ name: 'error', params: { errorType: ErrorTypes.UNKNOWN } }).catch(handleRouteRejection)
        const params = { errorType: ErrorTypes.UNKNOWN }
        if (err.response !== undefined) {
          const responseJSON = await err.response.json()
          if (responseJSON.error.code === 'announcement_failed') {
            params.errorType = ErrorTypes.ANNOUNCEMENT_FAILED
          }
        }
        this.$router.push({ name: 'error', params }).catch(handleRouteRejection)
        return
      }
    }

    this.$router.push({ name: 'confirmation' }).catch(handleRouteRejection)
  },

  beforeDestroy() {
    clearTimeout(this.showFeedbackTimeout)
  },

  methods: {
    startFeedbackTimeout() {
      this.showFeedbackTimeout = setTimeout(() => {
        this.hasElapsedTwoSeconds = true
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.label-creation,
.label-creation__feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label-creation {
  margin-top: 3em;
  clip-path: circle(6em at center); /* stylelint-disable-line */
  background-color: $sc-lightest-grey;
  min-height: 12em;
  display: flex;
  margin-bottom: 1em;
}

.label-creation__spinner {
  color: $sc-mid-grey;
}
</style>
